<template>
    <!-- 添加/编辑账号 -->
    <div class="addEditAccount">
        <div class="mask"></div>
        <div class="addEditAccountCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="cancelAccountFun"></i>
            </div>
            <el-form :model="agentListData" ref="agentListData" :rules="rules">
                <el-form-item label="登录账号" prop="account">
                    <el-input v-model="agentListData.account" placeholder="请输入登录账号" :disabled="catdTitle === '编辑账号'" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" :prop="catdTitle === '编辑账号' ? '' : 'paw'">
                    <el-input type="password" v-model="agentListData.paw" placeholder="请输入密码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" :prop="catdTitle === '编辑账号' ? '' : 'newPaw'">
                    <el-input type="password" v-model="agentListData.newPaw" placeholder="请输入确认密码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="账号类型" prop="type">
                    <el-select v-model="agentListData.type" placeholder="请选择账号类型">
                        <el-option v-for="(nature, index) in types" :key="index" :label="nature.name" :value="nature.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="agentListData.type === 4" label="关联代理商" prop="agent">
                    <el-select class="addressSty" @hook:mounted="cancalReadOnly" @visible-change="cancalReadOnly" @keyup.enter.native="searchClick" :filter-method="myAddressFocus" @change="myAddressChange" ref="select" v-model="agent" filterable placeholder="请输入关联代理商" :loading="ressloading">
                        <el-option v-for="item in agents" :key="item.value" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                    <i class="el-icon-search" @click="searchClick"></i>
                </el-form-item>
                <el-form-item label="联系人" prop="contact">
                    <el-input v-model="agentListData.contact" placeholder="请输入联系人"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                    <el-input v-model="agentListData.contactPhone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
            </el-form>
            <div class="button">
                <el-button @click="cancelAccountFun">取 消</el-button>
                <el-button type="primary" @click="deterAccountFun">提 交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'addEditAccount',
        props: {
            // 标题
            catdTitle: {
                type: String,
                default: '编辑账号',
            },
            // 账号信息
            agentList: {
                type: Object,
                default() {
                    return {}
                },
            },
        },
        data() {
            return {
                // 管理代理商 loading
                ressloading: false,
                // 关联代理商
                agent: '',
                agents: [],
                // 账号类型 下拉框
                types: [{ name: '超管', id: 1 }, { name: '代理商', id: 4 }],
                // 表单
                agentListData: {
                    // 员工姓名
                    name: '',
                },
                rules: {
                    account: [
                        {
                            required: true,
                            message: '请输入登录账号',
                            trigger: 'blur',
                        },
                    ],
                    paw: [
                        {
                            required: true,
                            message: '请输入密码',
                            trigger: 'blur',
                        },
                    ],
                    newPaw: [
                        {
                            required: true,
                            message: '请输入密码',
                            trigger: 'blur',
                        },
                    ],
                    type: [
                        {
                            required: true,
                            message: '请选择账号类型',
                            trigger: 'change',
                        },
                    ],
                    agent: [
                        {
                            required: true,
                            message: '请输入关联代理商',
                            trigger: 'blur',
                        },
                    ],
                    contact: [
                        {
                            required: true,
                            message: '请输入联系人',
                            trigger: 'blur',
                        },
                    ],
                    contactPhone: [
                        {
                            required: true,
                            message: '请输入联系电话',
                            trigger: 'blur',
                        },
                    ],
                },
                // 被选中的企业/组织id
                companyId: null,
                // 关联代理商id
                agentId: null,
            }
        },
        created() {
            console.log('this.agentList: ', this.agentList)
            const agentListData = {
                ...this.agentList,
            }
            if (this.catdTitle === '编辑账号') {
                agentListData.account = agentListData.username
                agentListData.type = agentListData.role
                // 代理商id
                this.agentId = agentListData.agent_id
                this.agent = agentListData.agent_name
                agentListData.contact = agentListData.name
                agentListData.contactPhone = agentListData.phone
            }
            this.agentListData = agentListData
        },
        methods: {
            // 点击取消
            cancelAccountFun() {
                this.$emit('cancelAccount-fun')
            },
            // 点击提交
            deterAccountFun() {
                if (
                    !this.agentListData.account ||
                    !this.agentListData.type ||
                    !this.agentListData.contact ||
                    !this.agentListData.contactPhone
                ) {
                    this.$message({
                        message: '必填项不能为空！',
                        type: 'error',
                    })
                    return false
                }
                if (this.catdTitle === '添加账号') {
                    if (!this.agentListData.paw || !this.agentListData.newPaw) {
                        this.$message({
                            message: '密码不能为空！',
                            type: 'error',
                        })
                        return false
                    }
                }
                if (this.agentListData.paw !== this.agentListData.newPaw) {
                    this.$message({
                        message: '两次密码不一致！',
                        type: 'error',
                    })
                    return false
                }
                if (this.agentListData.type === 4 && !this.agent) {
                    this.$message({
                        message: '关联代理商不能为空！',
                        type: 'error',
                    })
                    return false
                }

                let url = '/v1/admin-user/add'
                let url2 = '/v1/admin-user/edit'
                let value = {
                    password: this.agentListData.paw,
                    surePassword: this.agentListData.newPaw,
                    role: this.agentListData.type,
                    agentId: this.agentId,
                    name: this.agentListData.contact,
                    phone: this.agentListData.contactPhone,
                }
                if (this.catdTitle === '添加账号') {
                    this.addEditPost(
                        { ...value, username: this.agentListData.account },
                        url
                    )
                } else if (this.catdTitle === '编辑账号') {
                    this.addEditPost(
                        { ...value, adminId: this.agentListData.id },
                        url2
                    )
                }
            },
            // 添加/编辑代理商
            addEditPost(value, url) {
                this.$instance
                    .post(url, value)
                    .then(res => {
                        if (res.data.status === 200) {
                            this.$emit('deterAccount-fun')
                            this.$message({
                                message: this.catdTitle + '成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            // iphone element搜索选择器 调出小键盘
            cancalReadOnly(onOff) {
                this.$nextTick(() => {
                    if (!onOff) {
                        const Selects = this.$refs
                        // console.log(Selects) // 如果只有1个下拉框，这段就足够了---start
                        if (Selects.select) {
                            const input = Selects.select.$el.querySelector(
                                '.el-input__inner'
                            )
                            input.removeAttribute('readonly')
                        }
                        // 如果有多个，下方继续添加
                    }
                })
            },
            // 关联代理商-当 input 发生改变时
            myAddressFocus(val) {
                this.agent = val
                // 关联代理商id
                this.agents.map(val => {
                    if (this.agent === val.name) {
                        this.agentId = val.id
                    }
                })
            },
            myAddressChange() {
                // 关联代理商id
                this.agents.map(val => {
                    if (this.agent === val.name) {
                        this.agentId = val.id
                    }
                })
            },
            // 关联代理商-点击搜索图标
            searchClick() {
                this.$refs.select.focus()
                this.ressloading = true
                this.agentListGet()
            },
            // 代理商 列表
            agentListGet() {
                this.$instance
                    .get('/v1/agent/list', {
                        params: {
                            pageSize: 20,
                            // 搜索关键字 SN
                            agentName: this.agent,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.ressloading = false
                            this.agents = res.data.data.elements
                            // 关联代理商id
                            this.agents.map(val => {
                                if (this.agent === val.name) {
                                    this.agentId = val.id
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .addEditAccount {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        & ::v-deep .addEditAccountCard {
            width: 600px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .el-form {
                padding: 30px 40px 10px;
                border-top: 1px #f0f0f0 solid;
                border-bottom: 1px #f0f0f0 solid;
                .el-form-item {
                    margin-bottom: 20px;
                    .el-form-item__label {
                        width: 120px !important;
                        padding-right: 20px;
                        line-height: 32px;
                    }
                    .el-input__inner {
                        line-height: 32px !important;
                    }
                    .el-form-item__content {
                        margin-left: 120px !important;
                        line-height: 32px;
                        .el-select {
                            width: 100%;
                        }
                        // 搜索图标
                        .el-icon-search {
                            width: 34px;
                            position: absolute;
                            right: 0px;
                            text-align: center;
                            z-index: 2;
                            cursor: pointer;
                        }
                        .addressSty {
                            i {
                                display: none;
                            }
                        }
                        input {
                            height: 32px;
                        }
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
